import React, {useState} from 'react'
import {graphql} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../components/Layout";
import DiscussionEmbed from "disqus-react";
import * as i18nUtil from "i18n-util";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


export const query = graphql`
  query ($language: String!) {

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const About = () => {

  const {t, i18n: {language}} = useTranslation();
  const [isCommentVisible, setIsCommentVisible] = useState(false)

  return (
    <Layout pageTitle="About" showGoBack showBreadcrumbsWhenEmpty maxWidth="md">
      <Card>
        <CardContent sx={{px: {xs: 1, md: 2}}}>
          <Typography sx={{mb: {xs: 3}}} variant="title">{t("About")}</Typography>
          <p>
            {t("Hi, I'm just a software developer trying to make a living, being very happy doing my job and making the world a better place to live.")}
          </p>
          <p>
            {t("If you want to ask me any question, send a message to")}:
          </p>
          <p>
            <strong>email</strong> {t("[AT]")} <strong>devsou</strong> {t("[DOT]")} <strong>com</strong>
          </p>
          <p>
            {t("See ya")} :)
          </p>
          {isCommentVisible ? (
              <DiscussionEmbed
                shortname='devsou-com'
                config={
                  {
                    url: `https://devsou.com/about/`,
                    identifier: 'about',
                    title: t("About"),
                    language: i18nUtil.normalize(language),
                  }
                }
              />
          ) : (
            <Button size="large" fullWidth variant="contained"
                    onClick={() => setIsCommentVisible(true)}>{t("Load Disqus Comments")}</Button>
          )}
          <small className="d-block text-muted text-center">
            <span style={{fontSize: "15px"}}>©</span> 2023–{new Date().getFullYear()} - Devsou
          </small>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default About
